import { ListGroup } from 'react-bootstrap';
import { BsCartPlus } from 'react-icons/bs';

import { InfoCard } from '../controls/cards';
import { LeadFeatureDisabled } from './lead';

export const UpsellCard = ({ lead }) => {
    return (

        <InfoCard
            header="Offer enhancements"
            headerClass="bg-success text-white"
            subheader="Opportunities available"
            // subheader="Up to $19.36 more per month"
            text={<UpsellOpportunities lead={lead} />}
            icon={<BsCartPlus />}
        />
    )
}


const UpsellOpportunities = ({ lead }) => {

    return (
        <LeadFeatureDisabled />
        // <ListGroup variant="flush">
        //     <ListGroup.Item>Switch to 20 year term ($43.20 monthly), $18.16 increase</ListGroup.Item>
        //     <ListGroup.Item>Add total disability premium waiver, $1.20 increase.</ListGroup.Item>
        // </ListGroup>
    )

}
