import {
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  Alert,
  Badge,
  Button,
  Card,
  CardGroup,
  Col,
  Container,
  ListGroup,
  Row,
  Tab,
  Tabs,
} from 'react-bootstrap';
import {
  BiChurch,
  BiSolidCreditCardAlt,
  BiSolidPhoneCall,
} from 'react-icons/bi';
import {
  BsArrowLeftRight,
  BsCaretRight,
  BsCartPlus,
  BsEnvelopeAt,
  BsHospital,
  BsHouseHeart,
} from 'react-icons/bs';
import {
  FaBabyCarriage,
  FaBalanceScale,
  FaBirthdayCake,
  FaCar,
  FaCarCrash,
  FaHandsHelping,
  FaTemperatureLow,
} from 'react-icons/fa';
import {
  MdRefresh,
  MdSchool,
} from 'react-icons/md';
import { useParams } from 'react-router-dom';

import { QuoterCard } from '../app/quoters';
import { InfoCard } from '../controls/cards';
import { Hero } from '../controls/hero';
import { getLead } from '../libs/api';
import { asCurrency } from '../libs/i18n';
import { AlternativeCard } from './alternatives';
import { FaceValueCard } from './facevalue';
import {
  CostObjection,
  ProductObjection,
  TimingObjection,
  ValueObjection,
} from './objections';
import { SalesAdvisor } from './sales-tips';
import { Statistics } from './statistics';
import { Timeline } from './timeline';
import { UpsellCard } from './upsell';

export const TriggerCard = ({ lead }) => {


  const triggers = useMemo(() => {

    let icons = {};

    if (undefined !== lead && undefined !== lead.age) {

      if (lead.current_age < 30) {
        icons["Mortgage"] = <BsHouseHeart />;
        icons["Family"] = <FaBabyCarriage />;
        icons["Payments"] = <FaCar />;
        icons["Near miss"] = <FaCarCrash />;
      } else {
        if (lead.current_age < 40) {
          icons["Family"] = <FaBabyCarriage />;
          icons["Education"] = <MdSchool />;
          icons["Debt"] = <BiSolidCreditCardAlt />;
          icons["Health scare"] = <BsHospital />
        } else {
          icons["Debt"] = <BiSolidCreditCardAlt />;
          icons["Charity"] = <FaHandsHelping />;
          icons["Funerals"] = <BiChurch />;
          icons["Birthday"] = <FaBirthdayCake />;
        }
      }

    }


    return icons;
  }, [lead.current_age]);



  return (
    <Card className='mb-2'>

      <Card.Header>Common buy triggers in this segment</Card.Header>
      <Card.Body className="text-center">
        <Badge>not available in demo</Badge>

      </Card.Body>

      {/* <ListGroup>
        {Object.entries(triggers).map(([key, icon]) => (
          <ListGroup.Item key={key}>
            <h5>
              {icon} {key}
            </h5>
          </ListGroup.Item>
        ))}
      </ListGroup> */}
    </Card>
  );

}
