
export const getLead = ({ id, next }) => {
    next({
        "type": "session",
        "id": "Fo_LrpWdySsGFKTvd2FEO",
        "brand": "qat-belowcost-app.link-nonprod.wawanesalife.com",
        "started": 1677705960933,
        "date": "2023-03-01",
        "outcome": "continue",
        "producttype_id": "6224",
        "producttemplate_id": "564",
        "utm_campaign": "2022",
        "utm_source": "default",
        "utm_medium": "link",
        "browser_name": "chrome",
        "browser_version": "109.0.0",
        "os": "Windows 10",
        "latest_step": "consent",
        "latest_ts": 1677705960933,
        "landing_start": 1677705960933,
        "disqualified": {},
        "term": "10",
        "quote": "Fo_LrpWdySsGFKTvd2FEO",
        "session": "2ybpyA0sJC3SjSHvmPxRk",
        "broker": "belowcost",
        "username": "fa0d16cd-92cf-4817-9a08-7849cc7a81d9",
        "user_attributes": [
            {
                "Name": "sub",
                "Value": "fa0d16cd-92cf-4817-9a08-7849cc7a81d9"
            },
            {
                "Name": "email",
                "Value": "belowcost-2ybpyA0sJC3SjSHvmPxRk@example.com"
            }
        ],
        "user_created": "2023-03-01T21:26:02.566Z",
        "token": "eyJraWQiOiJsc3U5d1BEUk9UaFdMcFwvNmFqUFJLcUw5OTVCUVpNdzY1TWhoOWppMUZhST0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJmYTBkMTZjZC05MmNmLTQ4MTctOWEwOC03ODQ5Y2M3YTgxZDkiLCJjb2duaXRvOmdyb3VwcyI6WyJiZWxvd2Nvc3QiXSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmNhLWNlbnRyYWwtMS5hbWF6b25hd3MuY29tXC9jYS1jZW50cmFsLTFfSmw4THBySXdCIiwiY2xpZW50X2lkIjoiOTZoNXJtaHM4NWZiaXI2Y3Nja3RkZzEwcCIsIm9yaWdpbl9qdGkiOiIxM2VlZTU2OS02NjMyLTQ4ZDItYjc1ZC0yOGRiMDI3MDNjNzQiLCJldmVudF9pZCI6IjMwY2JhZjc2LTgzYzUtNGJhNi1iNGJhLTZkNmFiZDYyNWVkMiIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiYXdzLmNvZ25pdG8uc2lnbmluLnVzZXIuYWRtaW4iLCJhdXRoX3RpbWUiOjE2Nzc3MDU5NjMsImV4cCI6MTY3NzcxMzE2MywiaWF0IjoxNjc3NzA1OTYzLCJqdGkiOiIzZjRlZGY1MS1jYjVkLTRhN2QtYmI2NS1hNzViOGZkNDVlNTIiLCJ1c2VybmFtZSI6ImZhMGQxNmNkLTkyY2YtNDgxNy05YTA4LTc4NDljYzdhODFkOSJ9.GVyUJdksvauZEzu89LVR-VzWRdJ7siUnvVHee_cxUfVfs9BJk-UlxgIiD89ItzjmVa-1HjgEgqTle8YsFIfMulmeyjplo2lPQVfB5SzyWMgoX7N81TiiDByLri9V_C7hYtVjerta1wiDko8C0oPKpshfPkPm48R6WUt9_N3aHgFuicVb5KgpgOu9Z8Kf-19dEuJbPN3TOYBZzB2OeIwJVAvdNcxmucRWjGwXc4-UwCv0zdxM-fUPjdo-_zzRnyr35qYFe9Op489BLM0gScrJJxaASMOomNdt_X6-9_7_hbPj-RwT6J0wd2y3GVANYPhvKx18H7etE0yTVfF-cSbeEw",
        "success": true,
        "asOf": 1677706090328,
        "geo_version": 1677705962708,
        "geo_isp": "Zayo",
        "geo_country": "Canada",
        "geo_city": "Winnipeg",
        "geo_organization": "Wawanesa Mutual Insurance Company",
        "geo_province_code": "MB",
        "geo_longitude": -79.3716,
        "geo_province": "Manitoba",
        "geo_timezone": "America/Winnipeg",
        "geo_postal": "",
        "geo_country_code": "CA",
        "geo_latitude": 43.6319,
        "geo_source": "cache",
        "ab": "b",
        "province": "Manitoba",
        "province_current": "mb",
        "province_birth": "mb",
        "city": "Winnipeg",
        "country": "Canada",
        "postal_code": "A1A 1A1",
        "approve_company": "Link Platform",
        "approve_user": "link_belowcost",
        "licensed": "yes",
        "happyPath": {
            "landing": "quoter",
            "quoter": "privacy",
            "privacy": "lifestyle",
            "lifestyle": "introduction",
            "introduction": "address",
            "address": "diagnosis",
            "diagnosis": "last10",
            "last10": "last5",
            "last5": "last2",
            "last2": "misc",
            "misc": "medical",
            "medical": "occupation",
            "occupation": "beneficiary",
            "beneficiary": "payment",
            "payment": "consent",
            "consent": "policy"
        },
        "next": "/consent",
        "landing_end": 1677705965774,
        "quoter_start": 1677705965774,
        "help_clicked": [
            {
                "facevalue": 1677705965847
            }
        ],
        "facevalue": 214000,
        "dob": "1986-01-01",
        "gender": "M",
        "smoking": "No",
        "smoker": "No",
        "premium": 25.04,
        "offers": {
            "term10": {
                "smoker": {
                    "rider": {
                        "monthly": "46.03"
                    },
                    "norider": {
                        "monthly": "42.76"
                    }
                },
                "nonsmoker": {
                    "rider": {
                        "monthly": "26.77"
                    },
                    "norider": {
                        "monthly": "25.04"
                    }
                }
            },
            "term20": {
                "smoker": {
                    "rider": {
                        "monthly": "70.68"
                    },
                    "norider": {
                        "monthly": "67.41"
                    }
                },
                "nonsmoker": {
                    "rider": {
                        "monthly": "33.70"
                    },
                    "norider": {
                        "monthly": "31.97"
                    }
                }
            }
        },
        "age": 37,
        "rider": "no",
        "modified": "yes",
        "quoter_end": 1677705976754,
        "privacy_start": 1677705976754,
        "privacy": "Yes",
        "privacy_end": 1677705980979,
        "lifestyle_start": 1677705980979,
        "weight": "150 lbs",
        "height": "6'0",
        "physical": {
            "feet": "6",
            "inches": 0,
            "cm": 183,
            "units": {
                "height": "imperial",
                "weight": "imperial"
            },
            "height": "6'0",
            "lbs": "150",
            "kg": 68,
            "weight": "150 lbs"
        },
        "physical_status": "pass",
        "question_4350": {
            "id": "6744",
            "copy": "None, or less than weekly",
            "path": "application",
            "order": 1
        },
        "lifestyle_4350": "6744",
        "question_4351": {
            "id": "6748",
            "copy": "None, or less than weekly",
            "path": "application",
            "order": 1
        },
        "lifestyle_4351": "6748",
        "purpose": "Personal",
        "lifestyle_end": 1677705989635,
        "introduction_start": 1677705989635,
        "email": "bobrichard2@gmail.com",
        "phone": "(204) 123-4567",
        "first_name": "Bob",
        "last_name": "Richard",
        "replacement": "A6642",
        "question_4297": {
            "id": "6642",
            "text": "No"
        },
        "citizenship": "A6643",
        "question_4298": {
            "id": "6643",
            "text": "Yes"
        },
        "truthful": "A6650",
        "question_4303": {
            "id": "6650",
            "text": "Yes"
        },
        "introduction_end": 1677706012499,
        "address_start": 1677706012499,
        "address1": "291 main st",
        "address_end": 1677706020627,
        "diagnosis_start": 1677706020627,
        "question_4310": {
            "id": "6664",
            "text": "No",
            "path": "application"
        },
        "question_4307": {
            "id": "6658",
            "text": "No",
            "path": "application"
        },
        "question_4308": {
            "id": "6660",
            "text": "No",
            "path": "application"
        },
        "question_4312": {
            "id": "6668",
            "text": "No",
            "path": "application"
        },
        "question_4311": {
            "id": "6666",
            "text": "No",
            "path": "application"
        },
        "question_4306": {
            "id": "6656",
            "text": "No",
            "path": "application"
        },
        "question_4309": {
            "id": "6662",
            "text": "No",
            "path": "application"
        },
        "diagnosis_end": 1677706030527,
        "last10_start": 1677706030527,
        "question_4314": {
            "id": "6671",
            "text": "No",
            "path": "application"
        },
        "question_4315": {
            "id": "6673",
            "text": "No",
            "path": "application"
        },
        "last10_end": 1677706034722,
        "last5_start": 1677706034722,
        "question_4317": {
            "id": "6676",
            "text": "No",
            "path": "application"
        },
        "question_4318": {
            "id": "6678",
            "text": "No",
            "path": "application"
        },
        "question_4319": {
            "id": "6680",
            "text": "No",
            "path": "application"
        },
        "last5_end": 1677706037468,
        "last2_start": 1677706037468,
        "last2_end": 1677706038867,
        "misc_start": 1677706038867,
        "question_4337": {
            "id": "6712",
            "text": "No",
            "path": "application"
        },
        "question_6713": {
            "text": "No",
            "path": "application"
        },
        "question_6714": {
            "text": "No",
            "path": "application"
        },
        "question_6715": {
            "text": "No",
            "path": "application"
        },
        "question_6716": {
            "text": "No",
            "path": "application"
        },
        "question_6717": {
            "text": "No",
            "path": "application"
        },
        "question_6718": {
            "text": "No",
            "path": "application"
        },
        "question_6719": {
            "text": "No",
            "path": "application"
        },
        "question_6720": {
            "text": "No",
            "path": "application"
        },
        "question_6721": {
            "text": "No",
            "path": "application"
        },
        "question_6722": {
            "text": "No",
            "path": "application"
        },
        "question_6723": {
            "text": "No",
            "path": "application"
        },
        "question_6724": {
            "text": "No",
            "path": "application"
        },
        "question_4344": {
            "id": "6726",
            "text": "No",
            "path": "application"
        },
        "question_4345": {
            "id": "6728",
            "text": "No",
            "path": "application"
        },
        "question_4346": {
            "id": "6730",
            "text": "No",
            "path": "application"
        },
        "question_4347": {
            "id": "6732",
            "text": "No",
            "path": "application"
        },
        "question_4304": {
            "id": "6653",
            "text": "No",
            "path": "application"
        },
        "question_4352": {
            "id": "6750",
            "text": "No",
            "path": "application"
        },
        "question_4353": {
            "id": "6752",
            "text": "No",
            "path": "application"
        },
        "question_4354": {
            "id": "6754",
            "text": "No",
            "path": "application"
        },
        "question_4355": {
            "id": "6756",
            "text": "No",
            "path": "application"
        },
        "misc_end": 1677706050021,
        "medical_start": 1677706050021,
        "medical_end": 1677706051268,
        "occupation_start": 1677706051268,
        "question_4321": {
            "id": "6683",
            "text": "No",
            "path": "application"
        },
        "question_4322": {
            "text": "No",
            "path": "application"
        },
        "question_4323": {
            "text": "No",
            "path": "application"
        },
        "question_4324": {
            "text": "No",
            "path": "application"
        },
        "question_4325": {
            "text": "No",
            "path": "application"
        },
        "question_4326": {
            "text": "No",
            "path": "application"
        },
        "question_4327": {
            "text": "No",
            "path": "application"
        },
        "question_4328": {
            "text": "No",
            "path": "application"
        },
        "question_4329": {
            "text": "No",
            "path": "application"
        },
        "question_4330": {
            "text": "No",
            "path": "application"
        },
        "question_4331": {
            "text": "No",
            "path": "application"
        },
        "question_4332": {
            "id": "6703",
            "text": "No",
            "path": "application"
        },
        "question_4333": {
            "id": "6705",
            "text": "No",
            "path": "application"
        },
        "question_4334": {
            "id": "6707",
            "text": "No",
            "path": "application"
        },
        "question_4335": {
            "id": "6709",
            "text": "No",
            "path": "application"
        },
        "occupations_0": {
            "id": 0,
            "text": "No",
            "path": "application"
        },
        "occupations_1": {
            "id": 1,
            "text": "No",
            "path": "application"
        },
        "occupation_end": 1677706055195,
        "beneficiary_start": 1677706055195,
        "question_4299": {
            "id": "6646",
            "text": "No",
            "path": "application"
        },
        "verification_code": "8682",
        "beneficiary_name": "ben",
        "beneficiary_relationship": "friend",
        "beneficiary_minor": "No",
        "beneficiary_contingent": "No",
        "beneficiary_end": 1677706068732,
        "payment_start": 1677706068732,
        "beneficiary_relationship_id": 38,
        "payment_bank": "123",
        "payment_frequency": "monthly",
        "payment_address": "123",
        "payment_transit": "123",
        "payment_account": "123",
        "payment_day": "12",
        "payment_end": 1677706077925,
        "consent_start": 1677706077925,
        "signatureInfo": {
            "url": "https://sandbox.e-signlive.ca/authentication?target=https%3A%2F%2Fsandbox.e-signlive.ca%2Ftransaction%2F_WvdnO3ssEdaeqXje66RwzBF6jU%3D%2Fsign&loginToken=b0NvSG0yd2s5Y3hlUHV5YlVWbzI4em1BK29HUGJYempFb1FBYm52ODFxQmlGU20yS29YdXNoakJjYVV0N0ZjdXA3Z05GV0Y4REpoWEUwZEtaRkFCVGNzYitMb2VGRDlCZGlFS0hDWFhyanBncXJ2OGM4bmZScU1VWDRMdGs4RFVrUFJ3OC9FZVd3NTVMYm1JU3hyWHExVTRVRFpLUjNsVFEwcDNNRmsxWVZFNWRtTTRNekpTY0hkRGVYbzNORUZOZUdaSmNqaE1VR1ZyVEhneVJrcE9ZbEJpYUZaSmNuVlZhRzgyWlRaVVdtWE5BWDVPTFpPTE8veEJXNk52T001Rm9CNEJMb1VncnA0N0lkREd6Q1ZnbUE9PQ%3D%3D",
            "packageId": "_WvdnO3ssEdaeqXje66RwzBF6jU=",
            "roleId": "ef585aa0-3e19-4b80-ba0c-bd046985ec55"
        },
        "packageId": "_WvdnO3ssEdaeqXje66RwzBF6jU=",
        "sessionId": "fa0d16cd-92cf-4817-9a08-7849cc7a81d9#3f4edf51-cb5d-4a7d-bb65-a75b8fd45e52",
        "modifiedBy": "0814310e-e271-59b1-89ca-6d7727db08a0",
        "modifiedTs": 1677706090965
    });
}
