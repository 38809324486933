import {
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  Alert,
  Badge,
  Button,
  Card,
  CardGroup,
  Col,
  Container,
  ListGroup,
  Row,
  Tab,
  Tabs,
} from 'react-bootstrap';
import {
  BiChurch,
  BiSolidCreditCardAlt,
  BiSolidPhoneCall,
} from 'react-icons/bi';
import {
  BsArrowLeftRight,
  BsCalculator,
  BsCaretRight,
  BsCartPlus,
  BsEnvelopeAt,
  BsHouseHeart,
} from 'react-icons/bs';
import {
  FaBabyCarriage,
  FaBalanceScale,
  FaCar,
  FaHandsHelping,
} from 'react-icons/fa';
import {
  MdRefresh,
  MdSchool,
} from 'react-icons/md';
import { useParams } from 'react-router-dom';

import { QuoterCard } from '../app/quoters';
import { InfoCard } from '../controls/cards';
import { Hero } from '../controls/hero';
import { getLead } from '../libs/api';
import { asCurrency } from '../libs/i18n';
import { QuickTermSimpleQuoter } from '../quoters/quickterm';
import { LeadFeatureDisabled } from './lead';
import {
  CostObjection,
  ProductObjection,
  TimingObjection,
  ValueObjection,
} from './objections';
import { SalesAdvisor } from './sales-tips';
import { Statistics } from './statistics';
import { Timeline } from './timeline';
import { UpsellCard } from './upsell';

export const AlternativeCard = ({ lead }) => {

  const [view, setView] = useState('default');



  return (
    <>
      {'default' === view ?
        <InfoCard
          header="Alternatives"
          headerClass="bg-warning text-white"
          subheader="Opportunities available"
          text={<Alternatives lead={lead} setView={setView} />}
          icon={<BsArrowLeftRight />}
        />
        :
        <>
          <InfoCard
            header="Instant quote"
            headerClass="bg-warning text-white"
            text={<QuickTermSimpleQuoter cancelButton={<Button onClick={() => setView('default')}><BsCalculator /> Cancel</Button>} />}
            icon={<BsCalculator />}
          />
        </>
      }

    </>
  )
}

const Alternatives = ({ lead, setView }) => {

  return (
    <>
      <LeadFeatureDisabled />
      {/* <p>
                Lower coverage to $145,000 to get the same premium for 20 year term
            </p>
            <Button onClick={() => setView('quoter')}><BsCalculator /> Instant quote</Button> */}

    </>
  )

}