// config for controlroom
export const assets = "https://trn-insuretech-assets.link-nonprod.wawanesalife.com";
export const gateway = "https://insuretech-controlroom-api.link-nonprod.wawanesalife.com";
export const brokerConfig = {
  "authorized_mb": "yes", "authorized_sk": "yes", "authorized_qc": "no", "authorized_nb": "yes", "authorized_ns": "yes","enabled": "yes", "authorized_on": "yes", "authorized_ab": "yes", "authorized_bc": "yes", "split": 0.5,
  "name": "insuretech",
  "broker": "insuretech",
  "display": "insuretech",
  "topic": "arn:aws:sns:ca-central-1:965766005558:trn-insuretech-admin"
};
export const appConfig = {
      "BrokerPoolClientParameter": "4ln3893pmbmuf5g6m1s8n4slhr",
      "LoginUrlParameter": "https://live-controlroom-auth.auth.ca-central-1.amazoncognito.com/oauth2/authorize?client_id=4ln3893pmbmuf5g6m1s8n4slhr&response_type=code&scope=email+openid&redirect_uri=https%3A%2F%2Finsuretech-controlroom.link-nonprod.wawanesalife.com%2Fredirect.html",
      "AthenaDatabaseParameter": "#",
      "AppHostParameter": "insuretech-controlroom",
      "AthenaTableParameter": "#",
      "ApiHostParameter": "insuretech-controlroom-api",
      "DocumentsHostParameter": "insuretech-controlroom-docs",
      "auth_endpoint": "https://live-controlroom-auth.auth.ca-central-1.amazoncognito.com",
      "OaiParameter": "origin-access-identity/cloudfront/E2WKAMVJV1HY25",
      "auth_redirect": "https://insuretech-controlroom.link-nonprod.wawanesalife.com/redirect.html",
};
