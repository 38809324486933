import React, {
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';

import { init } from 'echarts';
import {
  Button,
  Card,
  Col,
  Container,
  Image,
  ListGroup,
  Nav,
  Navbar,
  NavDropdown,
  Row,
} from 'react-bootstrap';
import {
  BsBarChartLine,
  BsCartCheck,
  BsCloudDownload,
  BsGearFill,
  BsStar,
} from 'react-icons/bs';

import { LayoutContext } from '../libs/contexts';
import { FeaturedLeadsCard } from './featured';
import {
  CustomSearch,
  MostRecent,
  TopFacevalue,
  TopMotivated,
} from './top';

export const Leads = () => {

  const { layout } = useContext(LayoutContext);

  return (
    <Container fluid className="mt-4">

      <Row>
        <Col className='col-md-12 col-lg-6'>
          <MostRecent />
        </Col>
        <Col className='col-md-12 col-lg-6'>
          <TopMotivated />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col className='col-md-12 col-lg-6'>
          <TopFacevalue />
        </Col>
        <Col className='col-md-12 col-lg-6'>
          <CustomSearch />
        </Col>
      </Row>

    </Container>

  );
}

